<template>
  <ui-component-modal
    modalTitle="Delete option"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :buttonLabelConfirm="'Delete'"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :savingSuccessMessage="'Option deleted'"
    :savingErrorMessage="'Oops, something went wrong.'"
    :isSavingError="isDeletingError"
    :onClickSave="deleteOption"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <p>
        Are you sure you want to delete this option {{ option.OptionName }}?
      </p>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import cartProvider from '@/providers/cart'

export default {
  props: {
    option: {
      default: null,
      type: Object
    },
    showModal: {
      type: Boolean,
      default: false
    },
    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isDeleting: false,
      isDeletingError: false,
      isDeletingSuccess: false
    }
  },

  computed: {
    ...mapState('cartStore', ['cart'])
  },

  created() {},

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    deleteOption() {
      let self = this
      self.isDeleting = true

      cartProvider.methods
        .deleteCartOption(self.cart.CartKey, self.option.OptionId)
        .then(response => {
          if (response.status === 200) {
            self.isDeletingSuccess = true

            self.setCart(response.data)

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Option is deleted!'
            })

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch(error => {
          self.isDeletingError = true
        })
        .finally(() => {
          self.isDeleting = false
        })
    }
  }
}
</script>
